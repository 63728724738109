import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import "./styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Loading from "./utils/Loading";

const Home = lazy(() => import("./component/home"));
const Otp = lazy(() => import("./component/otp"));
const RegisterPIN = lazy(() => import("./component/pin/RegisterPIN"));
const AktivasiPIN = lazy(() => import("./component/pin/AktivasiPIN"));
const Register = lazy(() => import("./component/formRegister/Register"));
const EndPage = lazy(() => import("./component/endPage"));
const BackToApps = lazy(() => import("./component/backToApps"));
const PaymentInit = lazy(() => import("./Pages/Payment/SSO"));
const PaymentPIN = lazy(() => import("./component/pin/PaymentPIN"));
const QRISPin = lazy(() => import("./component/pin/QrisPIN"));
const QRISPayment = lazy(() => import("./Pages/Payment/QRIS/Qris"));
const MenuBayarQris = lazy(() => import("./Pages/Payment/QRIS/MenuBayar"));
const DetailQris = lazy(() => import("./Pages/Payment/QRIS/DetailPembayaran"));
const Test = lazy(() => import("./Pages/Payment/QRIS/test"));
const TestCom = lazy(() => import("./Pages/test"));

export default function App() {
  return (
    <div className="containers-body">
      <BrowserRouter>
        <Suspense fallback={Loading()}>
          <Switch>
            <Route exact path="/activate" name="home" component={Home} />
            <Route exact path="/otp" name="otp" component={Otp} />
            <Route exact path="/test" name="test" component={TestCom} />
            <Route
              exact
              path="/payment/qris/test"
              name="qris"
              component={Test}
            />
            <Route
              exact
              path="/register"
              name="register"
              component={Register}
            />
            <Route
              exact
              path="/register/pin"
              name="pin"
              component={RegisterPIN}
            />
            <Route
              exact
              path="/aktivasi/pin"
              name="pin"
              component={AktivasiPIN}
            />
            <Route
              exact
              path="/backtoapps"
              name="backToApps"
              component={BackToApps}
            />
            {/* //Payment Route */}
            <Route
              exact
              path="/payment"
              name="Payment"
              component={PaymentInit}
            />
            <Route
              exact
              path="/payment/pin"
              name="pin"
              component={PaymentPIN}
            />
            <Route
              exact
              path="/payment/qris"
              name="qris"
              component={QRISPayment}
            />
            <Route
              exact
              path="/payment/qris/pay"
              name="qris"
              component={MenuBayarQris}
            />
            <Route
              exact
              path="/payment/qris/pin"
              name="qris"
              component={QRISPin}
            />
            <Route
              exact
              path="/payment/qris/detail"
              name="qris"
              component={DetailQris}
            />
            {/* //Payment Route */}
            {/* //EndPage */}
            <Route exact path="/end-page" name="endPage" component={EndPage} />
            {/* //EndPage */}
            <Redirect from="/" to="/activate" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}
