import { Spinner } from "reactstrap";
import logo from "../assets/logo/qoin.png";

const style = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 2,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const styleContainer = {
  // backgroundColor: "rgba(255,255,255,0.7)",
  backgroundColor: "rgba(0,0,0,0.7)",
  width: "100%",
  maxWidth: 420,
  height: "100%",
  position: "absolute",
  zIndex: 9999999,
};
const Loading = () => (
  <div className="loading">
    <div className="img-load">
      {/* <Spinner size="lg" color="warning" /> */}
      <img src={logo} alt="" className="spin" />
    </div>
  </div>
);

export default Loading;
